frappe.provide("golfy.utils")

golfy.utils.CheckIn = class CheckIn {
    /**
     * Should be constructed at refresh of the listview
     */
    constructor(opts) {
        this.opts = opts || {}
        this.initialize()
    }

    initialize() {
        this.setup_defaults()
        this.setup_components()
    }

    setup_defaults() {
        this.opts.checked_in_workflow_states = ["Checked In", "Ready To Play", "Playing", "Finished"]
        this.opts.check_in_action = this.opts.check_in_action || "Check In"
        this.opts.check_in_action_position = this.opts.check_in_action_position || "primary"
        this.opts.scan_qr_code_fieldname = this.opts.scan_qr_code_fieldname || "scan_qr_code"
        this.opts.pax_fieldname = this.opts.pax_fieldname || "pax"
        this.opts.golf_course_fieldname = this.opts.golf_course_fieldname || "golf_course"
        this.opts.player_name_fieldname = this.opts.player_name_fieldname || "player_name"
        this.opts.golf_class_fieldname = this.opts.golf_class_fieldname || "golf_class"
        this.opts.price_list_fieldname = this.opts.price_list_fieldname || "price_list"
        this.opts.scheduled_caddy_number_fieldname = this.opts.scheduled_caddy_number_fieldname || "scheduled_caddy_number"
        this.opts.custom_locker_fieldname = this.opts.custom_locker_fieldname || "custom_locker"
        this.opts.tee_time_fieldname = this.opts.tee_time_fieldname || "tee_time"
        this.opts.number_of_holes_fieldname = this.opts.number_of_holes_fieldname || "number_of_holes"
        this.opts.note_fieldname = this.opts.note_fieldname || "note"
        this.opts.pax_regex = /^PA\d{10,11}$/
        this.opts.caddy_regex = /^CD\d{2,4}$/
        locals["Scan QR Code"] = locals["Scan QR Code"] || {}
    }

    setup_components() {
        this.setup_check_in_action()
        this.setup_check_out_action()
        this.setup_express_check_out_action()
    }

    setup_check_in_action() {
        const page = this.opts.page
        if (!page) {
            console.warn("Page is not defined. Please pass the page instance to CheckIn")
            return
        }
        if (page.custom_actions.find(`button:contains('${__("Check In (F2)")}')`).length) {
            return
        }
        if (this.opts.check_in_action_position === "primary") {
            page.add_button(__("Check In (F2)"), this.show_check_in_dialog.bind(this), { btn_class: "btn-primary" })
        } else {
            page.add_button(__("Check In (F2)"), this.show_check_in_dialog.bind(this), {})
        }
        frappe.ui.keys.add_shortcut({
            shortcut: "f2",
            description: __("Check In"),
            page: page,
            action: this.show_check_in_dialog.bind(this),
        })
    }

    setup_check_out_action() {
        const page = this.opts.page
        if (!page) {
            console.warn("Page is not defined. Please pass the page instance to CheckIn")
            return
        }
        if (page.custom_actions.find(`button:contains('${__("Check Out (F4)")}')`).length) {
            return
        }
        let checked_out_btn = page.add_button(__("Check Out (F4)"), () => window.open("/app/point-of-sale", "_blank"))

        frappe.ui.keys.add_shortcut({
            shortcut: "f4",
            description: __("Check Out"),
            page: page,
            action: () => checked_out_btn.trigger("click"),
        })
    }

    setup_express_check_out_action() {
        const page = this.opts.page
        if (!page) {
            console.warn("Page is not defined. Please pass the page instance to CheckIn")
            return
        }
        if (page.custom_actions.find(`button:contains('${__("Express Checkout (Shift+F4)")}')`).length) {
            return
        }
        let express_check_out_btn = page.add_button(__("Express Checkout (Shift+F4)"), () => window.open("/app/express-checkout/new", "_blank"))

        frappe.ui.keys.add_shortcut({
            shortcut: "shift+f4",
            description: __("Express Checkout"),
            page: page,
            action: () => express_check_out_btn.trigger("click"),
        })
    }

    show_check_in_dialog() {
        const self = this
        golfy.utils.requires_golf_course.call(this, (golf_course) => {
            this.check_locker_mode(golf_course).then((locker_mode) => {
                const fields = [
                    {
                        label: __("Scan QR Code"),
                        description: __("Scan the bag tag (PAX)"),
                        fieldname: self.opts.scan_qr_code_fieldname,
                        fieldtype: "Data",
                        options: "Barcode",
                        focus: 1,
                        onchange: self.on_scan_qr_code_changed.bind(self),
                    },
                    {
                        fieldname: "__column_break_1",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("PAX"),
                        description: __("Or type short numbers of the PAX"),
                        fieldname: self.opts.pax_fieldname,
                        fieldtype: "Link",
                        options: "POS Invoice",
                        reqd: 1,
                        filters: {
                            posting_date: ["Timespan", "today"],
                            workflow_state: "Confirmed",
                            custom_golf_course: golf_course,
                            custom_golf_schedule: ["is", "set"],
                        },
                        only_select: true,
                        change: self.on_pax_changed.bind(self),
                    },
                    {
                        fieldname: "__section_break_1",
                        fieldtype: "Section Break",
                    },
                    {
                        fieldname: "__column_break_2",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Player Name"),
                        fieldname: self.opts.player_name_fieldname,
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        label: __("Golf Class"),
                        fieldname: self.opts.golf_class_fieldname,
                        fieldtype: "Link",
                        options: "Customer",
                        read_only: 1,
                    },
                    {
                        label: __("Price List"),
                        fieldname: self.opts.price_list_fieldname,
                        fieldtype: "Link",
                        options: "Price List",
                        read_only: 1,
                    },
                    {
                        label: __("Note"),
                        fieldname: self.opts.note_fieldname,
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        fieldname: "__column_break_3",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Scheduled Caddy Number"),
                        fieldname: self.opts.scheduled_caddy_number_fieldname,
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        label: __("Number Of Holes"),
                        fieldname: self.opts.number_of_holes_fieldname,
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        label: __("Tee Time"),
                        fieldname: self.opts.tee_time_fieldname,
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        fieldname: self.opts.golf_course_fieldname,
                        fieldtype: "Data",
                        default: golf_course,
                        hidden: 1,
                    },
                ]

                // Chỉ hiển thị Locker Number nếu locker_mode khác "None"
                if (locker_mode !== "Disabled") {
                    const locker_field = {
                        label: __("Locker"),
                        fieldname: self.opts.custom_locker_fieldname,
                        fieldtype: "Data",
                    }
                    if (locker_mode === "Required") {
                        locker_field.reqd = 1
                    }

                    fields.push(locker_field)
                }

                self.dialog = new frappe.ui.Dialog({
                    title: __("Check In"),
                    fields: fields,
                    primary_action_label: __("Check In"),
                    no_submit_on_enter: true,
                    primary_action: async function () {
                        const { pax } = self.dialog.get_values()
                        frappe.dom.freeze(__("Checking in..."))
                        self.check_in()
                            .then(() => {
                                frappe.show_alert({
                                    message: __('Successfully checked in PAX "{0}"', [pax]),
                                    indicator: "green",
                                })
                                self.dialog.clear()
                                self.dialog.fields_dict[self.opts.scan_qr_code_fieldname].set_focus()
                                self.opts?.listview?.refresh()
                            })
                            .finally(() => {
                                frappe.dom.unfreeze()
                            })
                    },
                })
                self.dialog.show()
            })
        })
    }

    check_locker_mode(golf_course) {
        return frappe.db.get_value("Golf Course", golf_course, "locker_mode").then((r) => {
            return r.message?.locker_mode
        })
    }

    on_scan_qr_code_changed(event) {
        frappe.flags.dialog_set = false
        const input = event?.target?.value || ""
        if (input === "") {
            return
        }
        this.handle_qr_code(input)
            .then((result) => {
                console.log(`Scanned QR Code: ${input}`)
                event.target.value = ""
            })
            .catch((error) => {
                console.error(`Handle QR code error`, error)
                if (error?.message) {
                    event.target.value = ""
                    frappe.show_alert({
                        message: error.message,
                        indicator: error.indicator || "red",
                    })
                }
            })
    }

    reset_scanner() {
        this.dialog.fields_dict[this.opts.scan_qr_code_fieldname]?.set_value("")
    }

    update_scan_description(description) {
        this.dialog.fields_dict[this.opts.scan_qr_code_fieldname]?.set_description(description || "")
    }

    handle_qr_code(input) {
        if (this.opts.pax_regex.test(input)) {
            return this.handle_scan_pax(input)
        }
        return Promise.reject(`Invalid QR Code: ${input}`)
    }

    handle_scan_pax(input) {
        const self = this
        return new Promise((resolve, reject) => {
            if (!self.opts.pax_regex.test(input)) {
                reject(`Invalid PAX: ${input}`)
                return
            }
            const prev_pax = self.dialog.get_value(self.opts.pax_fieldname)
            if (prev_pax === input) {
                resolve()
                return
            }
            self.search_pax(input).then((doc) => {
                const { pax, golf_course, workflow_state, date } = doc
                if (golf_course !== self.dialog.get_value("golf_course")) {
                    reject({
                        message: __('PAX "{0}" is not scheduled in the current golf course.', [pax]),
                    })
                    return
                }
                if (this.opts.checked_in_workflow_states.includes(workflow_state)) {
                    reject({
                        message: __('PAX "{0}" is already checked in.', [pax]),
                    })
                    return
                }
                if (workflow_state !== "Confirmed") {
                    reject({
                        message: __('PAX "{0}" is "{1}", that is not valid to check in.', [pax, workflow_state]),
                    })
                    return
                }
                const today = frappe.datetime.get_today()
                if (date !== today) {
                    reject({
                        message: __('PAX "{0}" is not scheduled for today.', [pax]),
                    })
                    return
                }
                self.set_pax_fields(doc)
                self.dialog.set_value(self.opts.pax_fieldname, pax)
                resolve()
            })
        })
    }

    search_pax(pax) {
        return new Promise((resolve, reject) => {
            if (locals["Scan QR Code"][pax]) {
                resolve(locals["Scan QR Code"][pax])
                return
            }
            frappe
                .xcall("golfy.golfy.api.scan_qrcode", {
                    search_value: pax,
                })
                .then((result) => {
                    if (result) {
                        locals["Scan QR Code"][pax] = result
                        resolve(result)
                        return
                    }
                    reject(__("PAX not found"))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    set_pax_fields(doc) {
        const { player_name, golf_class, price_list, scheduled_caddy_number, tee_time, number_of_holes, note } = doc
        this.dialog.set_value(this.opts.player_name_fieldname, player_name)
        this.dialog.set_value(this.opts.golf_class_fieldname, golf_class)
        this.dialog.set_value(this.opts.price_list_fieldname, price_list)
        this.dialog.set_value(this.opts.scheduled_caddy_number_fieldname, scheduled_caddy_number)
        this.dialog.set_value(this.opts.number_of_holes_fieldname, number_of_holes)
        this.dialog.set_value(this.opts.tee_time_fieldname, frappe.format(tee_time, { fieldtype: "Time" }))
        this.dialog.set_value(this.opts.note_fieldname, note)
    }

    on_pax_changed() {
        const pax = this.dialog.get_value(this.opts.pax_fieldname)
        if (!pax) {
            return
        }
        this.search_pax(pax).then(this.set_pax_fields.bind(this))
    }

    check_in() {
        return frappe
            .xcall("frappe.model.workflow.apply_workflow", {
                doc: {
                    doctype: "POS Invoice",
                    name: this.dialog.get_value(this.opts.pax_fieldname),
                },
                action: this.opts.check_in_action,
            })
            .then((doc) => {
                const custom_locker = this.dialog.get_value(this.opts.custom_locker_fieldname)
                const pax = this.dialog.get_value(this.opts.pax_fieldname)
                const golf_course = this.dialog.get_value(this.opts.golf_course_fieldname)

                return this.check_locker_mode(golf_course).then((locker_mode) => {
                    if (locker_mode !== "Disabled" && custom_locker !== "") {
                        return frappe.xcall("golfy.golfy.api.update_golf_flight_player", {
                            pax: pax,
                            custom_locker: custom_locker,
                        })
                    }
                    return Promise.resolve()
                })
            })
    }
}
