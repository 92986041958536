window.get_tee_label = function (hole_number) {
    function ordinal(n) {
        if (n % 100 >= 10 && n % 100 <= 20) {
            return `${n}th`
        }
        const suffixes = { 1: "st", 2: "nd", 3: "rd" }
        const suffix = suffixes[n % 10] || "th"
        return `${n}${suffix}`
    }

    const tee_order = Math.floor((hole_number - 1) / 9) * 9 + 1
    return `${ordinal(tee_order)} Tee`
}
