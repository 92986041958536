frappe.provide("frappe.views")

const required_libs = ["assets/frappe/js/frappe/views/factory.js", "assets/frappe/js/frappe/list/list_view_select.js"]
frappe.require(required_libs, function () {
    const FrappeListViewSelect = frappe.views.ListViewSelect

    frappe.views.TeeView = class TeeView extends frappe.views.ListView {
        get view_name() {
            return "Tee"
        }

        setup_defaults() {
            super.setup_defaults()
            this.view = "Tee"
            this.list_view_settings = this.list_view_settings || {}
            this.list_view_settings.disable_count = true
            this.tee_view_settings = this.tee_view_settings || {
                tees: ["1st Tee", "10th Tee"],
            }
            this.setup_formatters()
        }

        setup_result_area() {
            this.$result = $(`<table class="result table-tee table table-bordered">`)
            this.$frappe_list.append(this.$result)
        }

        current_golf_course() {
            let filters = this.get_filters_for_args()
            return filters.find((filter) => filter?.[1] === "golf_course" && filter?.[2] === "=" && filter?.[3])?.[3]
        }

        current_date() {
            let filters = this.get_filters_for_args()
            return filters.find((filter) => filter?.[1] === "date" && filter?.[2] === "=" && filter?.[3])?.[3]
        }

        setup_freeze_area() {
            this.$freeze = $(`
                <tr>
                    <td colspan="8" class="text-center">
                    ${__("Loading")}...
                    </td>
                </tr>
            `).hide()
            this.$result.append(this.$freeze)
        }

        setup_columns() {
            // setup columns for tee view
            this.columns = []
            this.tee_view_settings.tees.forEach((tee, idx) => {
                const tee_prefix = this.get_tee_prefix(idx)
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("T/T"),
                        fieldname: `tee_time`,
                    },
                    custom_options: {
                        rowspan_field: "_tee_time_rowspan",
                        class_mames: ["align-middle", "text-center"],
                    },
                })
                this.columns.push({
                    // type: "Subject",
                    type: "Field",
                    df: {
                        label: __("No"),
                        fieldname: `_tee_time_children_idx`,
                        fieldtype: "Small Text",
                    },
                    custom_options: {
                        class_mames: ["align-middle", "text-center"],
                    },
                })
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("Player"),
                        fieldname: `${tee_prefix}player_name`,
                    },
                    custom_options: {
                        tee_prefix: tee_prefix,
                        class_mames: ["align-middle", "border-right-none"],
                        // remove_if_empty: true,
                    },
                })
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("PAX"),
                        fieldname: `${tee_prefix}workflow_state`,
                    },
                    custom_options: {
                        tee_prefix: tee_prefix,
                        class_mames: ["align-middle", "text-right", "border-left-none"],
                        // remove_if_empty: true,
                    },
                })
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("PAX"),
                        fieldname: `${tee_prefix}pax`,
                    },
                    custom_options: {
                        tee_prefix: tee_prefix,
                        class_mames: ["align-middle", "text-right"],
                        // remove_if_empty: true,
                    },
                })
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("CD"),
                        fieldname: `${tee_prefix}scheduled_caddy_number`,
                        fieldtype: "Small Text",
                    },
                    custom_options: {
                        tee_prefix: tee_prefix,
                        class_mames: ["align-middle", "text-center"],
                    },
                })
                this.columns.push({
                    type: "Field",
                    df: {
                        label: __("Note"),
                        fieldname: `${tee_prefix}note`,
                        fieldtype: "Small Text",
                    },
                    custom_options: {
                        tee_prefix: tee_prefix,
                        rowspan_field: "_schedule_children",
                        class_mames: ["align-middle", "text-note"],
                        // remove_if_empty: true,
                    },
                })
            })
        }

        get_tee_prefix(idx) {
            return `_${idx === 0 ? "1st" : 9 * idx + 1 + "th"}_tee_`
        }

        get_header_html() {
            const tees_row1 = this.tee_view_settings?.tees?.map((tee) => `<th colspan="7" class="text-center">${tee}</th>`)?.join("")
            const tees_row2 = this.tee_view_settings?.tees?.map((tee, index) => {
                return `
                    <th class="text-center">${__("T/T")}</th>
                    <th class="text-center">${__("No")}</th>
                    <th class="text-left" colspan="2">${__("Player")}</th>
                    <th class="text-right">${__("Bag")}</th>
                    <th class="text-center">${__("CD")}</th>
                    <th class="text-center">${__("Note")}</th>
                `
            })
            return `
                <thead class="list-row-head">
                    <tr>${tees_row1 || ""}</tr>
                    <tr>${tees_row2 || ""}</tr>
                </thead>
            `
        }

        get_call_args() {
            this.method = "golfy.golfy.doctype.golf_schedule.get_golf_schedules"
            return super.get_call_args()
        }

        prepare_data(r) {
            const _golf_course = this.current_golf_course()
            const _date = this.current_date()
            let data = r.message || {}
            const golf_schedules = data.golf_schedules || []
            const tees = data.tees || []
            const tee_times = data.tee_times || []

            // Update tee_view_settings
            this.tee_view_settings = {
                ...this.tee_view_settings,
                tees,
            }
            // this.render_header(true)

            // extract user_info for assignments
            Object.assign(frappe.boot.user_info, data.user_info)
            delete data.user_info

            data = !Array.isArray(data) ? frappe.utils.dict(data.keys, data.values) : data

            // Init by tee time
            const nodes = tee_times.map((tee_time, idx, _tee_times) => {
                const next_tee_time = _tee_times[idx + 1] || "23:59:59"
                return {
                    tee_time,
                    next_tee_time,
                }
            })
            // Fill data by tee time and tee
            golf_schedules
                .filter(({ tee_time, tee }) => tee_time && tee)
                .forEach((golf_schedule) => {
                    const { tee_time, tee } = golf_schedule
                    const node = nodes.find(({ tee_time: _start, next_tee_time: _end }) => this.is_between_tee_times(tee_time, _start, _end))
                    if (!node) {
                        console.warn(`Invalid tee_time`, golf_schedule)
                        return
                    }
                    node[tee] = node[tee] || []
                    node[tee].push(golf_schedule)
                })
            const keys = new Set()
            nodes.forEach((node, node_idx) => {
                const max_rows = tees.map((tee) => node?.[tee]?.map((m) => m?.players?.length || 0)?.reduce((a, b) => a + b, 0) || 0).reduce((a, b) => Math.max(a, b), 4)
                const rows = []
                for (let row_idx = 0; row_idx < max_rows; row_idx++) {
                    rows.push({
                        tee_time: node.tee_time,
                        _golf_course,
                        _date,
                        _odd_or_even: node_idx % 2 === 0 ? "odd" : "even",
                        _tee_time_rowspan: row_idx === 0 ? max_rows : 0,
                        _tee_time_children_idx: row_idx + 1,
                        _has_any_players: false,
                    })
                }
                tees.forEach((tee, tee_idx) => {
                    if (!node?.[tee]?.length) return
                    const tee_prefix = this.get_tee_prefix(tee_idx)
                    let _offset = 0
                    node[tee].forEach(({ players, ...golf_schedule }) => {
                        if (!players?.length) return
                        players.forEach((player, i) => {
                            if (!player) return
                            const workflow_state = player.workflow_state || golf_schedule.workflow_state || "Draft"
                            const obj = {
                                golf_schedule,
                                ...player,
                                name: golf_schedule.name,
                                player_idx: i + 1,
                                workflow_state,
                            }
                            rows[i + _offset]["_has_any_players"] = true
                            Object.keys(obj).forEach((key) => {
                                rows[i + _offset][`${tee_prefix}${key}`] = obj[key]
                            })
                        })
                        _offset += players.length
                    })
                })
                node.rows = rows
                rows.flatMap((row) => Object.keys(row)).forEach((key) => keys.add(key))
            })

            const flatten_rows = []
            let idx = 1
            nodes.forEach((node) => {
                node.rows.forEach((row) => {
                    row.idx = `${idx}`
                    flatten_rows.push(row)
                    idx++
                })
            })

            const auto_hide_rows = this.auto_hide(flatten_rows, _date)

            if (this.start === 0) {
                this.data = auto_hide_rows
            } else {
                this.data = this.data.concat(auto_hide_rows)
            }
        }

        auto_hide(flatten_rows, date) {
            if (!flatten_rows) {
                return []
            }

            const today = frappe.datetime.now_date()
            const day_diff = frappe.datetime.get_day_diff(date, today)
            if (day_diff > 0) {
                console.log(`date is in the future, skip auto_hide`)
                return flatten_rows
            }

            let auto_hide_rows = [...flatten_rows]

            const decrease_rowspan = (row) => {
                if (!auto_hide_rows) {
                    return
                }
                for (let i = auto_hide_rows.length - 1; i >= 0; i--) {
                    let r = auto_hide_rows[i]
                    if (r.tee_time === row.tee_time && r._tee_time_rowspan > 1) {
                        r._tee_time_rowspan -= 1
                        return
                    }
                }
            }

            const hide_row = (row) => {
                auto_hide_rows = auto_hide_rows.filter((r) => r.idx !== row.idx)
                decrease_rowspan(row)
            }

            const seconds = Math.max(this.time_to_seconds(frappe.datetime.now_time()) - 300, 0)
            for (let i = flatten_rows.length - 1; i >= 0; i--) {
                const row = flatten_rows[i]
                if (row._has_any_players) {
                    continue
                }
                if (day_diff === 0 && this.time_to_seconds(row.tee_time) >= seconds) {
                    continue
                }
                hide_row(row)
            }

            return auto_hide_rows
        }

        is_between_tee_times(tee_time, start_time, end_time) {
            if (!tee_time || !start_time || !end_time) {
                return false
            }
            const tee_time_seconds = this.time_to_seconds(tee_time)
            const start_time_seconds = this.time_to_seconds(start_time)
            const end_time_seconds = this.time_to_seconds(end_time)
            return tee_time_seconds >= start_time_seconds && tee_time_seconds < end_time_seconds
        }

        time_to_seconds(time) {
            const [hours, minutes, seconds] = time.split(":").map(Number)
            return hours * 3600 + minutes * 60 + seconds
        }

        render() {
            frappe.model.with_doctype("POS Invoice", () => {
                this.render_header(true)
                this.setup_columns()
                this.render_list()
                this.bind_custom_events()
            })
        }

        bind_custom_events() {
            this.$result.find(".btn-add-custom").bind("click", (event) => {
                console.log(`add click`)
                event.preventDefault()
                const $btn = $(event.currentTarget)
                frappe.new_doc("Golf Schedule", {}, (doc) => {
                    doc.golf_course = $btn.attr("data-golf_course")
                    doc.date = $btn.attr("data-date")
                    doc.tee_time = $btn.attr("data-tee_time")
                    doc.tee = $btn.attr("data-tee")
                })
            })
            this.$result.find(".btn-copy-custom").bind("click", (event) => {
                event.preventDefault()
                const $btn = $(event.currentTarget)
                frappe.utils.copy_to_clipboard($btn.prev().text())
            })
        }

        refresh() {
            return this.get_tee_view_settings().then(() => {
                super.refresh()
            })
        }

        get_tee_view_settings() {
            this.tee_view_settings = this.tee_view_settings || {}
            let golf_course = this.current_golf_course()
            if (!golf_course) {
                console.warn(`golf_course is not selected`)
                return Promise.resolve()
            }
            if (this.tee_view_settings[golf_course]) {
                console.log(`tee_view_settings is already fetched`)
                return Promise.resolve()
            }
            return frappe.run_serially([
                () => frappe.dom.freeze(),
                () => this.fetch_golf_course(golf_course),
                // () => this.fetch_tee_times(golf_course, date),
                () => this.init_tees(golf_course),
                () => this.refresh_columns(),
                () => frappe.dom.unfreeze(),
            ])
        }

        fetch_golf_course(golf_course) {
            return frappe.db.get_doc("Golf Course", golf_course).then((golf_course_doc) => {
                const { course_code, course_name, number_of_holes } = golf_course_doc
                this.tee_view_settings[golf_course] = {
                    course_code,
                    course_name,
                    number_of_holes,
                }
            })
        }

        init_tees(golf_course) {
            const number_of_holes = parseInt(this.tee_view_settings?.[golf_course]?.number_of_holes) || 0
            const lanes = number_of_holes / 9
            let tees = Array.from(Array(lanes).keys()).map((index) => {
                const hole_number = index * 9 + 1
                return __(get_tee_label(hole_number))
            })
            this.tee_view_settings.tees = tees // TODO remove this in the future
            this.tee_view_settings[golf_course].tees = tees
        }

        get_meta_html(doc) {
            // console.log(`get_meta_html is called`)
            return ""
        }

        get_list_row_html(doc) {
            // console.log(`get_list_row_html is called`)
            const left = this.get_left_html(doc)
            const right = this.get_right_html(doc)
            return `<tr class="list-row-container list-row-${doc["_odd_or_even"]}">${left}${right}</tr>`
        }

        get_column_html(col, doc) {
            if (!doc["name"] && col?.custom_options?.remove_if_empty) {
                return ""
            }
            let colspan = col?.custom_options?.colspan || 1
            if (col?.custom_options?.colspan_field) {
                colspan = doc[col.custom_options.colspan_field]
            }
            let rowspan = col?.custom_options?.rowspan || 1
            if (col?.custom_options?.rowspan_field) {
                rowspan = doc[col.custom_options.rowspan_field]
            }
            if (colspan === 0 || rowspan === 0) {
                return ""
            }
            let custom_classes = (col?.custom_options?.class_mames || []).join(" ")
            if (col.type === "Status" || col.df?.options == "Workflow State") {
                let show_workflow_state = col.df?.options == "Workflow State"
                return `
                    <td class="list-row-col hidden-xs ellipsis ${custom_classes}" colspan="${colspan ?? 1}" rowspan="${rowspan ?? 1}">
                        ${this.get_indicator_html(doc, show_workflow_state)}
                    </td>
                `
            }

            if (col.type === "Tag") {
                const tags_display_class = !this.tags_shown ? "hide" : ""
                let tags_html = doc._user_tags ? this.get_tags_html(doc._user_tags, 2, true) : '<div class="tags-empty">-</div>'
                return `
            <td class="list-row-col tag-col ${tags_display_class} hidden-xs ellipsis ${custom_classes}" colspan="${colspan ?? 1}" rowspan="${rowspan ?? 1}">
              ${tags_html}
            </td>
          `
            }

            const df = col.df || {}
            const label = df.label
            const fieldname = df.fieldname
            const link_title_fieldname = this.link_field_title_fields[fieldname]
            const value = doc[fieldname] || ""
            let value_display = link_title_fieldname ? doc[fieldname + "_" + link_title_fieldname] || value : value

            let translated_doctypes = frappe.boot?.translated_doctypes || []
            if (translated_doctypes.includes(df.options)) {
                value_display = __(value_display)
            }

            const format = () => {
                if (df.fieldtype === "Code") {
                    return value
                } else if (df.fieldtype === "Percent") {
                    return `
                        <div class="progress" style="margin: 0px;">
                            <div class="progress-bar progress-bar-success" role="progressbar"
                            aria-valuenow="${value}"
                            aria-valuemin="0" aria-valuemax="100" style="width: ${Math.round(value)}%;">
                            </div>
                        </div>
                    `
                } else {
                    return frappe.format(value, df, null, doc)
                }
            }

            const field_html = () => {
                let html
                let _value
                let strip_html_required = df.fieldtype == "Text Editor" || (df.fetch_from && ["Text", "Small Text"].includes(df.fieldtype))

                if (strip_html_required) {
                    _value = strip_html(value_display)
                } else {
                    _value = typeof value_display === "string" ? frappe.utils.escape_html(value_display) : value_display
                }

                if (df.fieldtype === "Rating") {
                    let out_of_ratings = df.options || 5
                    _value = _value * out_of_ratings
                }

                if (df.fieldtype === "Image") {
                    html = df.options
                        ? `<img src="${doc[df.options]}"
                                style="max-height: 30px; max-width: 100%;">`
                        : `<div class="missing-image small">
                                ${frappe.utils.icon("restriction")}
                                </div>`
                } else if (df.fieldtype === "Select") {
                    html = `
                        <span class="filterable indicator-pill ${frappe.utils.guess_colour(_value)} ellipsis"
                            data-filter="${fieldname},=,${value}">
                        <span class="ellipsis"> ${__(_value)} </span>
                        </span>
                    `
                } else if (df.fieldtype === "Link") {
                    html = `<a class="filterable ellipsis"
                    data-filter="${fieldname},=,${value}">
                    ${_value}
                </a>`
                } else if (["Text Editor", "Text", "Small Text", "HTML Editor", "Markdown Editor"].includes(df.fieldtype)) {
                    html = `<span class="ellipsis">
                    ${_value}
                </span>`
                } else {
                    html = `<a class="filterable ellipsis"
                        data-filter="${fieldname},=,${frappe.utils.escape_html(value)}">
                        ${format()}
                    </a>`
                }

                return `
                    <span class="ellipsis"
                    title="${__(label)}: ${frappe.utils.escape_html(_value)}">
                    ${html}
                    </span>
                `
            }

            const class_map = {
                Subject: "list-subject level",
                Field: "hidden-xs",
            }
            const css_class = ["list-row-col ellipsis", class_map[col.type], frappe.model.is_numeric_field(df) ? "text-right" : ""].join(" ")

            let column_html
            if (col.custom_options?.tee_prefix && this.settings?.formatters?.[fieldname.replace(col.custom_options.tee_prefix, "")] && col.type !== "Subject") {
                column_html = this.settings.formatters[fieldname.replace(col.custom_options.tee_prefix, "")](value, df, doc, col.custom_options.tee_prefix)
            } else if (this.settings.formatters && this.settings.formatters[fieldname] && col.type !== "Subject") {
                column_html = this.settings.formatters[fieldname](value, df, doc)
            } else {
                column_html = {
                    Subject: this.get_subject_element(doc, value_display).innerHTML,
                    Field: field_html(),
                }[col.type]
            }

            return `
                <td class="${css_class} ${custom_classes}" colspan="${colspan ?? 1}" rowspan="${rowspan ?? 1}">
                    ${column_html}
                </td>
            `
        }

        setup_formatters() {
            this.settings = this.settings || {}
            this.settings.formatters = this.settings.formatters || {}
            this.setup_tee_time_formatter()
            this.setup_player_name_formatter()
            this.setup_workflow_state_formatter()
            this.setup_pax_formatter()
            this.set_note_formatter()
        }

        setup_tee_time_formatter() {
            this.settings.formatters["tee_time"] = (tee_time, df, doc) => {
                if (typeof tee_time !== "string") {
                    return tee_time
                }
                // Function to add hours and minutes to a time string
                const add_time = (timeString, hoursToAdd, minutesToAdd) => {
                    // Parse the input time string
                    let [hours, minutes, seconds] = timeString.split(":").map(Number)

                    // Create a Date object starting from midnight (00:00:00)
                    let date = new Date()
                    date.setHours(hours, minutes, seconds, 0)

                    // Add the specified hours and minutes
                    date.setHours(date.getHours() + hoursToAdd)
                    date.setMinutes(date.getMinutes() + minutesToAdd)

                    // Format the new time back to HH:mm:ss
                    let newHours = String(date.getHours()).padStart(2, "0")
                    let newMinutes = String(date.getMinutes()).padStart(2, "0")
                    let newSeconds = String(date.getSeconds()).padStart(2, "0")

                    return `${newHours}:${newMinutes}:${newSeconds}`
                }
                let tee_turn = add_time(tee_time, 2, 15)
                return `
                <a href="#${tee_time}" title="${tee_time}"></a>
                <strong>${frappe.format(tee_time, { fieldtype: "Time" })}</strong>
                <br>
                <span>${frappe.format(tee_turn, { fieldtype: "Time" })}</span>
            `
            }
        }

        setup_player_name_formatter() {
            this.settings.formatters["player_name"] = (player_name, df, doc, tee_prefix) => {
                const build_player_name = (doc, tee_prefix) => {
                    if (!doc?.[`${tee_prefix}name`]) {
                        return ""
                    }
                    const style = ["Cancelled", "Closed"].includes(doc[`${tee_prefix}workflow_state`]) ? "color: #6B7280;text-decoration: line-through;" : "color: #3B82F6;"
                    return `
                        <a href="/app/golf-schedule/${doc[`${tee_prefix}parent`]}" title="${doc[`${tee_prefix}parent`]}">
                            <strong style="${style}">${doc[`${tee_prefix}player_name`]}</strong>
                        </a>
                    `
                }
                const build_meta = (doc, tee_prefix = "") => {
                    if (doc[`${tee_prefix}name`]) {
                        return ""
                    }
                    const tee = tee_prefix.replace(/^_(1st|\d+th)_tee_/gi, "$1 Tee")
                    const { tee_time, _golf_course, _date } = doc
                    let btn_variant = false ? "btn-secondary" : "btn-primary"
                    return `
                        <button
                            type="button"
                            class="btn ${btn_variant} icon-btn btn-add-custom"
                            style="display: inline"
                            data-golf_course="${_golf_course}"
                            data-date="${_date}"
                            data-tee_time="${tee_time}"
                            data-tee="${tee}">
                            <i class="fa fa-plus icon-sm"></i>
                        </button>
                    `
                }
                return [build_player_name(doc, tee_prefix), build_meta(doc, tee_prefix)].join("")
            }
        }

        build_uri(doc, tee_prefix) {
            const golf_schedule_name = doc?.[`${tee_prefix}golf_schedule`]?.name
            const golf_schedule_status = doc?.[`${tee_prefix}workflow_state`]
            const player_status = doc?.[`${tee_prefix}workflow_state`]
            if (player_status === "Draft" || golf_schedule_status === "Draft") {
                return `/app/golf-schedule/${golf_schedule_name}`
            }
            const pax = doc?.[`${tee_prefix}pax`]
            if (player_status && golf_schedule_status && pax) {
                return `/app/pos-invoice/${pax}`
            }
            return null
        }

        setup_workflow_state_formatter() {
            this.settings.formatters["workflow_state"] = (workflow_state, df, doc, tee_prefix) => {
                const build_status = (doc, tee_prefix) => {
                    if (!doc?.[`${tee_prefix}name`]) {
                        return ""
                    }
                    let _doc = {
                        doctype: "POS Invoice",
                        status: doc[`${tee_prefix}workflow_state`] || "Draft",
                    }
                    const indicator = frappe.get_indicator(_doc)
                    const uri = this.build_uri(doc, tee_prefix)
                    return `
                        <a href="${uri}" class="indicator-pill ${indicator[1]} filterable no-indicator-dot ellipsis" title='${indicator[0]}'>
                            <span class="ellipsis">${__(indicator[0])}</span>
                        </a>
                    `
                }
                return build_status(doc, tee_prefix)
            }
        }

        setup_pax_formatter() {
            this.settings.formatters["pax"] = (pax, df, doc, tee_prefix) => {
                const build_pax = (val, doc) => {
                    if (!val) {
                        return ""
                    }
                    return `
                        <a href="/app/pos-invoice/${val}" title="${doc["_name"]}" target="_blank">${val}</a>
                        <button
                            id="btn-copy-custom-${doc["idx"]}"
                            type="button"
                            class="btn btn-link btn-xs btn-copy-custom"
                            style="padding: 2px !important;">
                            <i class="fa fa-clipboard" aria-hidden="true"></i>
                        </button>
                    `
                }
                return build_pax(pax, doc)
            }
        }

        set_note_formatter() {
            this.settings.formatters["note"] = (note, df, doc, tee_prefix) => {
                const max_length = 30
                const escaped_note = frappe.utils.escape_html(note) ?? ""
                if (escaped_note.length <= max_length) {
                    return escaped_note
                }
                const summary = escaped_note.slice(0, max_length) + "..."
                return `
                    <span title="${escaped_note}">${summary}</span>
                    <i title="${escaped_note}" 
                        class="fa fa-eye"
                        style="cursor: pointer; margin-left: 5px;"
                        onclick="frappe.msgprint('${escaped_note}', '${__("Note")}')"></i>
                `
            }
        }

        render_count() {
            console.log(`render_count is called`)
            return ""
        }

        after_render() {
            this.setup_indicator()
            this.$page.find('.standard-filter-section > [data-fieldname="name"]').toggle(false)
            this.$filter_section.find(".sort-selector").toggle(false)
            this.$result.find(".list-subject a[data-name]").each((_, element) => {
                let text = $(element).text()
                $(element).parent().text(text)
            })
            this.$paging_area.addClass("hide")
        }

        setup_indicator() {
            const golf_course = this.current_golf_course()
            const date = this.current_date()
            if (golf_course && date) {
                const course_name = this.tee_view_settings?.[golf_course]?.course_name || golf_course
                this.page.set_indicator(
                    __("You are viewing the {0} schedules for {1}", [`<strong>${course_name}</strong>`, `<strong>${frappe.format(date, { fieldtype: "Date" })}</strong>`]),
                    "green",
                )
            } else {
                this.page.set_indicator(__("Choose a golf course and a date to check schedules or make a new reservation."), "orange")
            }
        }
    }

    frappe.views.TeeFactory = class TeeFactory extends frappe.views.Factory {
        make(route) {
            console.log("TeeFactory make page", route)
        }
    }

    frappe.views.ListViewSelect = class ListViewSelect extends FrappeListViewSelect {
        constructor(opts) {
            super(opts)
        }

        setup_views() {
            let self = this
            self.icon_map["Tee"] = "image-view"
            self.label_map["Tee"] = __("Tee View")
            const tee_view_doc_types = ["Golf Schedule", "Golf Flight"]
            const views = {
                Tee: {
                    condition: tee_view_doc_types.includes(self.doctype),
                    action: () => self.set_route("tee"),
                    current_view_handler: () => {
                        const icon = self.icon_map["Tee"]
                        const label = self.label_map["Tee"]
                        const dropdown_label = `<span class="hidden-xs">
                                ${frappe.utils.icon(icon)}
                                <span class="custom-btn-group-label">${__(label)}</span>
                                ${frappe.utils.icon("select", "xs")}
                            </span>
                            <span class="visible-xs">
                                ${frappe.utils.icon(icon)}
                            </span>`
                        self.list_view.views_menu.parent().find('button[data-toggle="dropdown"]').html(dropdown_label)
                    },
                },
                List: {
                    condition: true,
                    action: () => self.set_route("list"),
                },
                Report: {
                    condition: true,
                    action: () => self.set_route("report"),
                    current_view_handler: () => {
                        const reports = self.get_reports()
                        let default_action = {}
                        // Only add action if current route is not report builder
                        if (frappe.get_route().length > 3) {
                            default_action = {
                                label: __("Report Builder"),
                                action: () => self.set_route("report"),
                            }
                        }
                        self.setup_dropdown_in_sidebar("Report", reports, default_action)
                    },
                },
                Dashboard: {
                    condition: true,
                    action: () => self.set_route("dashboard"),
                },
                Calendar: {
                    condition: frappe.views.calendar[self.doctype],
                    action: () => self.set_route("calendar", "default"),
                    current_view_handler: () => {
                        self.get_calendars().then((calendars) => {
                            self.setup_dropdown_in_sidebar("Calendar", calendars)
                        })
                    },
                },
                Gantt: {
                    condition: frappe.views.calendar[self.doctype],
                    action: () => self.set_route("gantt"),
                },
                Inbox: {
                    condition: self.doctype === "Communication" && frappe.boot.email_accounts.length,
                    action: () => self.set_route("inbox"),
                    current_view_handler: () => {
                        const accounts = self.get_email_accounts()
                        let default_action
                        if (has_common(frappe.user_roles, ["System Manager", "Administrator"])) {
                            default_action = {
                                label: __("New Email Account"),
                                action: () => frappe.new_doc("Email Account"),
                            }
                        }
                        self.setup_dropdown_in_sidebar("Inbox", accounts, default_action)
                    },
                },
                Image: {
                    condition: self.list_view.meta.image_field,
                    action: () => self.set_route("image"),
                },
                Tree: {
                    condition: frappe.treeview_settings[self.doctype] || frappe.get_meta(self.doctype).is_tree,
                    action: () => self.set_route("tree"),
                },
                Kanban: {
                    condition: self.doctype != "File",
                    action: () => self.setup_kanban_boards(),
                    current_view_handler: () => {
                        frappe.views.KanbanView.get_kanbans(self.doctype).then((kanbans) => self.setup_kanban_switcher(kanbans))
                    },
                },
                Map: {
                    condition:
                        self.list_view.settings.get_coords_method ||
                        (self.list_view.meta.fields.find((i) => i.fieldname === "latitude") && self.list_view.meta.fields.find((i) => i.fieldname === "longitude")) ||
                        self.list_view.meta.fields.find((i) => i.fieldname === "location" && i.fieldtype == "Geolocation"),
                    action: () => self.set_route("map"),
                },
            }

            frappe.views.view_modes.forEach((view) => {
                if (self.current_view !== view && views[view].condition) {
                    self.add_view_to_menu(view, views[view].action)
                }

                if (self.current_view == view) {
                    views[view]?.current_view_handler && views[view].current_view_handler()
                }
            })
        }
    }

    function setup_custom_view(view) {
        if (!view || !frappe.router) {
            return
        }
        let slug = view.toLowerCase()
        if (!frappe.router.factory_views.includes(slug) && !frappe.views[view + "Factory"]) {
            frappe.router.factory_views.push(slug)
        }
        if (!frappe.router.list_views.includes(slug) && !frappe.views[view + "View"]) {
            frappe.router.list_views.push(slug)
        }
        if (!frappe.views.view_modes.includes(view)) {
            frappe.views.view_modes.unshift(view)
        }
        frappe.router.list_views_route[slug] = view
    }
    setup_custom_view("Tee")
})
