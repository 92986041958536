secondary_actions = ["Cancel"]

frappe.require("assets/frappe/js/frappe/form/workflow.js", () => {
    let FormStates = frappe.ui.form.States
    frappe.ui.form.States = class GolfyFormStates extends FormStates {
        show_actions() {
            var me = this
            super.show_actions().then(() => {
                me.frm.page.actions.find("li.user-action > a").each((index, action_item) => {
                    let $action_item = $(action_item)
                    let handler = $._data($action_item[0], "events")?.click?.[0]?.handler
                    if (!handler) {
                        return
                    }
                    let action_text = $action_item.text().trim()
                    $action_item.off("click").on("click", function (event) {
                        let self = this
                        frappe.msgprint({
                            title: __("Confirmation"),
                            message: __("Are you sure you want to <strong>{0}</strong>?", [action_text]),
                            primary_action: {
                                label: action_text,
                                action: () => {
                                    handler.call(self, event)
                                    frappe.hide_msgprint()
                                },
                            },
                        })
                    })
                    if (index !== 0 || secondary_actions.includes(action_text)) {
                        return
                    }
                    if (me.frm.page.primary_action) {
                        return
                    }
                    if (me.frm.doctype === "POS Invoice") {
                        $(".actions-btn-group").addClass("hide")
                        return
                    }
                    me.frm.page.set_primary_action(action_text, function () {
                        $action_item.trigger("click")
                    })
                    me.frm.page.actions_btn_group.find('button[data-toggle="dropdown"].btn-primary').removeClass("btn-primary").addClass("btn-secondary")
                })
            })
        }
    }
})
