frappe.provide("golfy.utils")

golfy.utils.Finish = class Finish {
    constructor(opts) {
        this.opts = opts || {}
        this.initialize()
    }

    initialize() {
        this.setup_defaults()
        this.setup_components()
    }

    setup_defaults() {
        this.opts.finish_action = this.opts.finish_action || "Finish"
        this.opts.finish_action_position = this.opts.finish_action_position || "primary"
        this.opts.pax_regex = /^PA\d{10,11}$/
        this.opts.caddy_regex = /^CD\d{2,4}$/
        locals["Scan QR Code"] = locals["Scan QR Code"] || {}
    }

    setup_components() {
        this.setup_finish_action()
    }

    setup_finish_action() {
        const page = this.opts.page
        if (!page) {
            console.warn("Page is not defined. Please pass the page instance to Finish")
            return
        }
        if (page.custom_actions.find(`button:contains('${__("Finish (F3)")}')`).length) {
            return
        }
        if (this.opts.finish_action_position === "primary") {
            page.add_button(__("Finish (F3)"), this.show_finish_dialog.bind(this), { btn_class: "btn-primary" })
        } else {
            page.add_button(__("Finish (F3)"), this.show_finish_dialog.bind(this), {})
        }
        frappe.ui.keys.add_shortcut({
            shortcut: "f3",
            description: __("Finidh"),
            page: page,
            action: this.show_finish_dialog.bind(this),
        })
    }

    show_finish_dialog() {
        const self = this
        golfy.utils.requires_golf_course.call(this, (golf_course) => {
            self.golf_course = golf_course
            self.dialog = new frappe.ui.Dialog({
                title: __("Finish"),
                fields: [
                    {
                        label: __("Scan QR Code"),
                        description: __("Scan the bag tag (PAX)"),
                        fieldname: "scan_qr_code",
                        fieldtype: "Data",
                        options: "Barcode",
                        focus: 1,
                        onchange: self.on_scan_qr_code_changed.bind(self),
                    },
                    {
                        fieldname: "__column_break_0a",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("PAX"),
                        description: __("Or type short numbers of the PAX"),
                        fieldname: "pax",
                        fieldtype: "Link",
                        options: "POS Invoice",
                        reqd: 1,
                        filters: {
                            posting_date: ["Timespan", "today"],
                            workflow_state: "Playing",
                            custom_golf_course: golf_course,
                            custom_golf_schedule: ["is", "set"],
                        },
                        only_select: true,
                        change: self.on_pax_changed.bind(self),
                    },
                    {
                        fieldname: "__column_break_0b",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Player Name"),
                        fieldname: "player_name",
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        fieldname: "__section_break_1",
                        fieldtype: "Section Break",
                    },
                    {
                        fieldname: "__column_break_1a",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Internal Note"),
                        fieldname: "internal_note",
                        fieldtype: "Data",
                        read_only_depends_on: "eval:!doc.pax;",
                    },
                    {
                        label: __("Acctual Holes"),
                        fieldname: "actual_holes",
                        fieldtype: "Int",
                        non_negative: 1,
                        read_only_depends_on: "eval:!doc.pax;",
                    },
                    {
                        fieldname: "__column_break_0b",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Caddy Number"),
                        fieldname: "caddy_number",
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        label: __("Actual Caddies"),
                        fieldname: "actual_caddies",
                        fieldtype: "Int",
                        non_negative: 1,
                        read_only_depends_on: "eval:!doc.pax;",
                    },
                    {
                        fieldname: "__column_break_0c",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Buggy Number"),
                        fieldname: "buggy_number",
                        fieldtype: "Data",
                        read_only: 1,
                    },
                    {
                        default: 0,
                        label: __("Single Buggy Surcharge"),
                        fieldname: "single_buggy_surcharge",
                        fieldtype: "Check",
                        read_only_depends_on: "eval:!doc.pax;",
                    },
                    {
                        label: __("Single Buggy Holes"),
                        fieldname: "single_buggy_holes",
                        fieldtype: "Int",
                        non_negative: 1,
                        depends_on: "single_buggy_surcharge",
                        mandatory_depends_on: "single_buggy_surcharge",
                    },
                    {
                        fieldname: "__section_break_2",
                        fieldtype: "Section Break",
                    },
                    {
                        fieldname: "__column_break_2a",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Golf Flight"),
                        fieldname: "parent",
                        fieldtype: "Link",
                        options: "Golf Flight",
                        read_only: 1,
                    },
                    {
                        fieldname: "__column_break_2b",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Golf Class"),
                        fieldname: "golf_class",
                        fieldtype: "Link",
                        options: "Customer",
                        read_only: 1,
                    },
                    {
                        fieldname: "__column_break_2c",
                        fieldtype: "Column Break",
                    },
                    {
                        label: __("Price List"),
                        fieldname: "price_list",
                        fieldtype: "Link",
                        options: "Price List",
                        read_only: 1,
                    },
                    {
                        fieldname: "golf_course",
                        fieldtype: "Data",
                        default: golf_course,
                        hidden: 1,
                    },
                ],
                primary_action_label: __("Save &amp; Finish"),
                no_submit_on_enter: true,
                primary_action: async function () {
                    const pax = self.dialog.get_value("pax")
                    frappe.dom.freeze(__("Finishing..."))
                    try {
                        await self.save("Finish")
                        frappe.show_alert({ message: __("PAX {0} Finished", [pax]), indicator: "green" })
                        self.dialog.hide()
                        self.opts?.listview?.refresh()
                    } catch (error) {
                        frappe.msgprint({ message: error.message || __("Error during finish"), indicator: "red" })
                    } finally {
                        frappe.dom.unfreeze()
                    }
                },
                secondary_action_label: __("Save"),
                secondary_action: function () {
                    const pax = self.dialog.get_value("pax")
                    frappe.dom.freeze(__("Save..."))
                    self.save()
                        .then(() => {
                            frappe.show_alert({
                                message: __('Successfully Save in PAX "{0}"', [pax]),
                                indicator: "green",
                            })
                            self.dialog.hide()
                            self.opts?.listview?.refresh()
                        })
                        .finally(() => {
                            frappe.dom.unfreeze()
                        })
                },
            })
            self.dialog.show()
        })
    }

    on_scan_qr_code_changed(event) {
        frappe.flags.dialog_set = false
        const input = event?.target?.value || ""
        if (input === "") {
            return
        }
        this.handle_qr_code(input)
            .then((result) => {
                console.log(`Scanned QR Code: ${input}`)
                event.target.value = ""
            })
            .catch((error) => {
                console.error(`Handle QR code error`, error)
                if (error?.message) {
                    event.target.value = ""
                    frappe.show_alert({
                        message: error.message,
                        indicator: error.indicator || "red",
                    })
                }
            })
    }

    reset_scanner() {
        this.dialog.fields_dict["scan_qr_code"]?.set_value("")
    }

    update_scan_description(description) {
        this.dialog.fields_dict["scan_qr_code"]?.set_description(description || "")
    }

    handle_qr_code(input) {
        if (this.opts.pax_regex.test(input)) {
            return this.handle_scan_pax(input)
        }
        return Promise.reject(`Invalid QR Code: ${input}`)
    }

    handle_scan_pax(input) {
        const self = this
        return new Promise((resolve, reject) => {
            if (!self.opts.pax_regex.test(input)) {
                reject(`Invalid PAX: ${input}`)
                return
            }
            const prev_pax = self.dialog.get_value("pax")
            if (prev_pax === input) {
                resolve()
                return
            }
            self.dialog.set_value("pax", input)
            self.on_pax_changed()
            resolve()
        })
    }

    on_pax_changed() {
        let self = this
        const pax = self.dialog.get_value("pax")
        if (!pax) {
            return
        }
        self.search_pax(pax)
            .then((result) => {
                self.update_fields(result)
            })
            .catch((error) => {
                console.error(`Search PAX error`, error)
            })
    }

    search_pax(pax) {
        return new Promise((resolve, reject) => {
            frappe
                .xcall("golfy.golfy.doctype.golf_flight_player.golf_flight_player.get_golf_flight_player_by_pax", {
                    pax,
                })
                .then((result) => {
                    if (result) {
                        locals["Scan QR Code"][pax] = result
                        resolve(result)
                        return
                    }
                    reject(__("PAX not found"))
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    update_fields(data) {
        const { pax, ...values } = data
        this.dialog.set_values(values)
    }

    async save(workflow_action = null) {
        const { pax, internal_note, actual_holes, actual_caddies, single_buggy_surcharge, single_buggy_holes } = this.dialog.get_values()
        await frappe.xcall("golfy.golfy.doctype.golf_flight_player.partial_update", {
            pax,
            internal_note,
            actual_holes,
            actual_caddies,
            single_buggy_surcharge,
            single_buggy_holes,
            workflow_action,
        })
    }
}
