frappe.provide("golfy.utils")

golfy.utils.current_golf_course = function () {
    if (typeof this.opts.golf_course === "function") {
        return this.opts.golf_course()
    }
    if (typeof this.opts.golf_course === "string") {
        return this.opts.golf_course
    }
    return null
}

golfy.utils.requires_golf_course = function (callback) {
    const self = this
    const golf_course = golfy.utils.current_golf_course.call(self)
    if (!golf_course) {
        frappe.msgprint({
            title: __("Please select a Golf Course"),
            message: __("Please select a Golf Course to proceed."),
        })
        return
    }
    callback(golf_course)
}
