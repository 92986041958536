frappe.provide("golfy.utils")

golfy.utils.PrintQRCode = class PrintQRCode {
    /**
     * Should be constructed at onload of the listview
     */
    constructor(opts) {
        this.opts = opts || {}
        this.initialize()
    }

    initialize() {
        this.setup_defaults()
        this.setup_components()
    }

    setup_defaults() {
        this.opts.print_doctype = this.opts.print_doctype || "POS Invoice"
    }

    setup_components() {
        this.setup_print_actions()
    }

    setup_print_actions() {
        const page = this.opts.page
        if (!page) {
            console.warn("Page is not defined. Please pass the page instance to PrintQRCode")
            return
        }
        const group = page.add_custom_button_group("", "printer")
        const toggle = group.parent(".custom-btn-group").find('[data-toggle="dropdown"]')
        toggle.find(".hidden-xs").remove()
        toggle.find(".visible-xs").removeClass("visible-xs")

        // Add buttons for booking and guest QR codes
        page.add_custom_menu_item(group, __("Print QR for Booking"), () => {
            this.show_print_for_booking()
        })
        page.add_custom_menu_item(group, __("Print QR for Guest"), () => {
            this.show_print_for_guest()
        })
    }

    show_print_for_booking() {
        const self = this
        golfy.utils.requires_golf_course.call(this, (golf_course) => {
            const tomorrow = frappe.datetime.add_days(frappe.datetime.get_today(), 1)
            frappe.model.with_doctype(this.opts.print_doctype, () => {
                const dialog = new frappe.ui.Dialog({
                    title: __("Print QR Code for Booking"),
                    fields: [
                        {
                            fieldname: "date",
                            label: __("Date"),
                            fieldtype: "Date",
                            default: tomorrow,
                            description: __("Please select the exact date you want to print."),
                            reqd: 1,
                        },
                        {
                            fieldtype: "Select",
                            label: __("Print Format"),
                            fieldname: "print_sel",
                            options: frappe.meta.get_print_formats(this.opts.print_doctype),
                            remember_last_selected_value: 1,
                        },
                    ],
                    primary_action: async function () {
                        const { date, print_sel } = dialog.get_values()
                        frappe
                            .call({
                                method: "golfy.golfy.api.print_qr_code_for_booking",
                                args: {
                                    golf_course,
                                    date,
                                    format: print_sel,
                                },
                            })
                            .then((response) => self.handle_print_async(dialog, response))
                    },
                    primary_action_label: __("Print"),
                })
                dialog.show()
            })
        })
    }

    handle_print_async(dialog, response) {
        let task_id = response.message.task_id
        frappe.realtime.task_subscribe(task_id)
        frappe.realtime.on(`task_complete:${task_id}`, (data) => {
            frappe.msgprint({
                title: __("Bulk PDF Export"),
                message: __("Your PDF is ready for download"),
                primary_action: {
                    label: __("Download PDF"),
                    client_action: "window.open",
                    args: data.file_url,
                },
            })
            frappe.realtime.task_unsubscribe(task_id)
            frappe.realtime.off(`task_complete:${task_id}`)
        })
        dialog.hide()
    }

    show_print_for_guest() {
        const self = this
        golfy.utils.requires_golf_course.call(this, (golf_course) => {
            const tomorrow = frappe.datetime.add_days(frappe.datetime.get_today(), 1)
            frappe.model.with_doctype(this.opts.print_doctype, () => {
                const dialog = new frappe.ui.Dialog({
                    title: __("Print QR Code for Guest"),
                    fields: [
                        {
                            fieldname: "date",
                            label: __("Date"),
                            fieldtype: "Date",
                            default: tomorrow,
                            description: __("Please select the exact date you want to print."),
                            reqd: 1,
                        },
                        {
                            fieldname: "quantity",
                            label: __("Quantity"),
                            fieldtype: "Int",
                            default: 10,
                            min: 1,
                            max: 30,
                            reqd: 1,
                        },
                        {
                            fieldtype: "Select",
                            label: __("Print Format"),
                            fieldname: "print_sel",
                            options: frappe.meta.get_print_formats(this.opts.print_doctype),
                            remember_last_selected_value: 1,
                        },
                    ],
                    primary_action: async function () {
                        const { date, quantity, print_sel } = dialog.get_values()
                        frappe
                            .call({
                                method: "golfy.golfy.api.print_qr_code_for_guest",
                                args: {
                                    golf_course,
                                    date,
                                    quantity,
                                    format: print_sel,
                                },
                            })
                            .then((response) => self.handle_print_async(dialog, response))
                    },
                    primary_action_label: __("Print"),
                })
                dialog.show()
            })
        })
    }
}
